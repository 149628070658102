import React, { useState } from 'react'
import { Navbar } from '../layout/navbar'
import './../style/donate.css'
import axios from 'axios';

 export const Donate = () => {



  const [amount, setAmount] = useState(null);

  //const apiUrl = "https://727instrumental.org/backend/public/api/ipn-handler";
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://727instrumental.org/backend/public/api/create-payment', { amount });
      console.log('Response Data:', response.data); // Check this in the console
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
    } catch (error) {
      if (error.response) {
        console.error('Response Error:', error.response);
        alert(`Response Error: ${error.response.status} - ${error.response.data}`);
      } else if (error.request) {
        console.error('No Response:', error.request);
        alert('No response received from the server.');
      } else {
        console.error('Error:', error.message);
        alert(`Error: ${error.message}`);
      }
    }
  };
  




  return (<>
    <div>
      <Navbar />

      <div>&nbsp;</div>
      <div className='left'>
        <h1>HELP SUPPORT OUR NEXT MUSICAL SUPERSTAR</h1>
        <div>&nbsp;</div>
        <h2 className='nextText'>TOGETHER WE CAN INSPIRE OUR YOUTH TO BE THE BEST THEY CAN BE</h2>
        <p className='filler'>
        <div>&nbsp;</div>
        No child should have to pass up playing in a school band because their family cannot afford to rent or buy a musical instrument for their child. With your support, the gift of music will not only benefit a student with a musical instrument of their choice along with 6 months of free lessons, it may also help shape their future
        <div>&nbsp;</div>
        Thank you, as your generous donation will directly impact our community and the youth we help serve!
        <div>&nbsp;</div>
          Thank you,<br />
          Mark DiGiorgio
        <div>&nbsp;</div>
        </p>
        
        <div>&nbsp;</div>
      </div>
      <div className='right'>

      <form onSubmit={handleSubmit}>

      <input type="hidden" name="cmd" value="_donations" />
    <input type="hidden" name="business" value="madmark13208@gmail.com" />
    <input type="hidden" name="item_name" value="Donation - 727 Instrumental" />
    <input type="hidden" name="currency_code" value="USD" />
    <input type="hidden" name="notify_url" value="https://727instrumental.org/backend/public/api/ipn-handler" />
    <input type="hidden" name="custom" value="935141728819206" />
    

        <div className='makeone1'>Make a Donation</div>
        <div>&nbsp;</div><div>&nbsp;</div>
        <div className='maindobox' style={{color: "#ffffff", fontSize: "18px", margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={1} onClick={() => setAmount(10)} /> $10.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={25} onClick={() => setAmount(25)} /> $25.00
          </div>
        </div>
            <div style={{clear: "both"}}>&nbsp;</div>

            <div className='maindobox' style={{color: "#ffffff", fontSize: "18px", margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={50} onClick={() => setAmount(50)} /> $50.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={100} onClick={() => setAmount(100)} /> $100.00
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div className='maindobox' style={{color: "#ffffff", fontSize: "18px",  margin: "0 auto"}}>
          <div className='donateBox'>
           <input type='radio' name='amount' value={250} onClick={() => setAmount(250)} /> $250.00
          </div>
          <div className='donateBox2'>
           <input type='radio' name='amount' value={500} onClick={() => setAmount(500)} /> $500.00
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          <div className='recurringDonateBox'>
           <input type='radio' name='amount'  value="" /> Other or Recurring Donation
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", margin: "0 auto"}}>
          
           <input className='donateBtn' type='submit' value={"Donate Now"} />
        
        </div>

        </form>

      </div>

    </div>
    </>
  )
}
