import React, { useEffect, useState } from 'react';
import { Navbar } from '../layout/navbar';
import './../style/gearUp.css'
import axios from 'axios';

export const Events = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get('https://727instrumental.org/backend/public/api/merchandise')
        .then(response => {
            setItems(response.data);
        })
        .catch(error => {console.error(("API CRASHED !", error));
        });
    },[]);
  return (
    <div>
        <Navbar />

        <h1 style={{color: "#fff"}}></h1>

        <h2 style={{color: "#fff"}}>"Under Construction"</h2>

        <div className='mainShopContainer'>
            <div className='Blocks4'>
                <div>
                    <img src='' alt='' className='itemImage' />
                </div>
                <div className='itemDes'>
                    <p style={{ color:"#ffffff"}}></p>
                </div>
                <div className='itemPrice'>
                   {/*  <p>Price: </p>*/}
                </div>
                <div className='shopBtn'>
                {/* <input type='submit' value="Buy Now" /> */}
                </div>
            </div>
        </div>

    </div>
  )
}