import React from 'react';
import { Navbar } from "../layout/navbar";
import './../style/aboutUs.css'

export const AboutUs = () => {
  return (
    <div>
        <Navbar />
        <div style={{fontSize: "60px", color: "#ffffff"}}> About Us </div>
        <div>&nbsp;</div>
        <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
        Back in 3rd grade at Webster Elementary School, I dreamed of learning to play the saxophone. A famous solo artist from the late 70s, who was featured on nearly every talk show at the time, inspired me to follow in his footsteps. As soon as I was eligible, I signed up for the school band without hesitation. I was given an alto saxophone, but my instructor, Mr. Mastroleo, informed me that after one month, I would have to return it unless my parents could purchase one for me.
        </div>
<div>&nbsp;</div>
            <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
            Before the month was up, I told my father we needed to buy a saxophone so I could continue playing. The next day, he told me he’d tried to find one but that the prices were far too high for us to afford. Heartbroken, I went to Mr. Mastroleo during the next band practice to explain that my parents couldn’t afford a saxophone and that I would have to leave the band.
            </div>

            <div>&nbsp;</div>

            <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
            Instead of letting me quit, Mr. Mastroleo said he would speak with the city school district to see if I could continue using the school’s saxophone. Sure enough, by the following week, he had arranged for me to borrow the instrument for the rest of the school year—and every year until 8th grade.
            </div>

            <div>&nbsp;</div>

            
            <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
            I started this nonprofit so no child would ever have to give up their dream of playing a musical instrument or miss the experience of being part of a school band. We are here to help and inspire the youth in our community.
            </div>

            <div><img src='./mee.png' className='imgmee' /></div>

            <div className='post'>
            MARK DIGIORGIO</div>
            <div className='post' style={{ marginTop:'0px'}}>
            President</div>


            <div>&nbsp;</div>
            <div style={{height: "5px", background: "#fff", margin: "0 auto", width: "90%"}}>&nbsp;</div>
            <div>&nbsp;</div>

            <div>


              <div className='leftabout'>

              <div><img src='./tre.png' className='imgmee2' /></div>

                <div className='post2'>
                 JOANNE GALLI</div>
                <div className='post2' style={{ marginTop:'0px'}}>
                Treasurer</div>

              </div>

              <div className='leftabout'>

              <div><img src='./maical.png' className='imgmee2' /></div>

                <div className='post2'>
                MICHAEL DIGIORGIO</div>
                <div className='post2 ' style={{ marginTop:'0px'}}>
                Secretary</div>

              </div>
            

            
            </div>

            

            <div>&nbsp;</div>

    </div>
  )
}